(function(){
   "use strict";

   angular.module("app.controllers").controller('ProductDetailedDialogController', function( $scope, $mdDialog, product ) {
      $scope.product = product;

      $scope.close = function() {
         $mdDialog.hide();
      };
      
   });

})();
