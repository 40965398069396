(function(){
   "use strict";

   var app = angular.module('app',
   [
      'app.controllers',
      'app.filters',
      'app.services',
      'app.directives',
      'app.routes',
      'app.config'
   ]);

   angular.module('app.routes', ['ui.router']);
   angular.module('app.controllers', ['ngMaterial', 'ui.router', 'ngMessages', 'angularFileUpload']);
   angular.module('app.filters', []);
   angular.module('app.services', ['ngResource', 'satellizer']);
   angular.module('app.directives', []);
   angular.module('app.config', ['ngMaterial']);
})();
