(function(){
   "use strict";

   angular.module("app.services").service('ProductRestService', function( $resource ) {
      return $resource("/api/product/:productId", { productId : "@productId" }, {

         find : { method : "GET", isArray : true },
         findById : { method : "GET" },
         findByCategory : { url : "/api/product/byCategory/:categoryId", method : "GET", isArray : true, params : { "categoryId" : "@categoryId"}},
         findByParentCategory : { url : "/api/product/byParentCategory/:categoryId", method : "GET", isArray : true, params : { "categoryId" : "@categoryId"}},
         create : { method : "POST" },
         update : { method : "POST" },
         remove : { method : "DELETE", headers: [{'Content-Type': 'application/json'}] }

      });
   });
})();
