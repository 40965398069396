(function(){
   "use strict";

   angular.module("app.controllers").controller('UserDialogController', function( $scope, $mdDialog, User, user ) {

      $scope.user = user || {};

      $scope.close = function() {
         $mdDialog.cancel();
      };

      $scope.save = function() {
         if ($scope.UserForm.$valid) {
            User.save($scope.user, function(user) {
               $mdDialog.hide(user);
            }, function() {
               $mdDialog.hide($scope.user);
            });
         } else {
            $scope.showErrors = true;
         }
      };

   });
})();
