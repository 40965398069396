(function(){
   "use strict";

   angular.module("app.directives").directive('categoriesBox', function() {
		return {
			scope : {},
			replace : true,
			templateUrl : "views/directives/categories/categories-widget.html",
			controller:  function( $scope, $rootScope, Category, $state ) {

            $scope.categories = Category.getBuiltCategories();
            $scope.allCount = Category.getAllCount();

            $rootScope.$on("UPDATE.CATEGORIES", function(event, categories) {
               $scope.categories = categories;
               $scope.allCount = Category.getAllCount();
            });

            $scope.openCategory = function(category) {
               $state.transitionTo("products-category", {categoryId : category.id});
            };

            $scope.toggleSubCategories = function(category) {
               category.opened = !category.opened;
            };
         }
      }
   });
})();
