(function(){
   "use strict";

   angular.module("app.controllers").controller('HeaderController', function( $scope, $mdSidenav, Dialog, Session ) {

      $scope.isAuthenticated = function() {
         return Session.isAuthenticated();
      };

      $scope.login = function(event) {
         Dialog.fromTemplate("login-dialog", "LoginDialogController", null, event);
      };

      $scope.logout = function(event) {
         Session.logout(event);
      };

      $scope.toggleSidenav = function() {
         $mdSidenav("left-sidenav").toggle();
      };

   });
})();
