(function(){
   "use strict";

   angular.module("app.controllers").controller('FooterController', function( $scope ) {

      $scope.contact = {};
      $scope.showErrors = false;

      $scope.submitContactForm = function() {
         if ($scope.ContactForm.$valid) {
            // sendEmail
         } else {
            $scope.showErrors = true;
         }
      };

   });
})();
