(function(){
   "use strict";

   angular.module("app.controllers").controller('ProductsController', function( $scope, Product ) {

      Product.find(function(products) {
         $scope.products = products;
      }, function() {

      });

   }).config(function($stateProvider) {
      $stateProvider.state('products', {
         url: '/products',
         views: {
            header: {
               templateUrl: '/views/app/public/header/header.html',
               controller: 'HeaderController'
            },
            sidenav: {
               templateUrl: '/views/app/public/sidenav/sidenav.html',
               controller: 'SidenavController'
            },
            main: {
               templateUrl: '/views/app/public/products/products.html',
               controller: 'ProductsController'
            },
            footer: {
               templateUrl: '/views/app/public/footer/footer.html',
               controller: 'FooterController'
            }
         }
      });
   });

})();
