(function(){
   "use strict";

   angular.module("app.controllers").controller('ProductsCategoryController', function( $scope, $stateParams, Product, Category ) {

      $scope.categoryId = $stateParams.categoryId;
      $scope.category = Category.getById($scope.categoryId);

      $scope.$on("UPDATE.CATEGORIES", function(event, categories) {
         $scope.category = Category.getById($scope.categoryId);
         loadProducts();
      });

      function loadProducts() {
         if (Category.isParent($scope.category.id)) {
            Product.findByParentCategory($scope.categoryId, function(products) {
               $scope.products = products;
            }, function() {

            });
         } else {
            Product.findByCategory($scope.category.id, function(products) {
               $scope.products = products;
            }, function() {

            });
         }
      }

      if ($scope.category) {
         loadProducts();
      }


      $scope.openDetailedDialog = function(product, event) {
         Product.openDetailedDialog(product, event);
      };

   }).config(function($stateProvider) {
      $stateProvider.state('products-category', {
         url: '/products/category/:categoryId',
         views: {
            header: {
               templateUrl: '/views/app/public/header/header.html',
               controller: 'HeaderController'
            },
            main: {
               templateUrl: '/views/app/public/products-category/products-category.html',
               controller: 'ProductsCategoryController'
            },
            footer: {
               templateUrl: '/views/app/public/footer/footer.html',
               controller: 'FooterController'
            }
         }
      });
   });

})();
