(function(){
   "use strict";

   angular.module("app.controllers").controller('SidenavController', function( $scope, $mdSidenav, Session, Dialog ) {

      $scope.isAuthenticated = function() {
         return Session.isAuthenticated();
      };

      $scope.login = function(event) {
         $mdSidenav("left-sidenav").close();
         Dialog.fromTemplate("login-dialog", "LoginDialogController", null, event);
      };

      $scope.logout = function(event) {
         $mdSidenav("left-sidenav").close();
         Session.logout(event);
      };

   });
})();
