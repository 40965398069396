(function(){
   "use strict";

   angular.module('app.routes').config( function($urlRouterProvider) {

      $urlRouterProvider.otherwise('/');
      //   TODO: Create session resolving. Then add resolving to state configs
      
   });
})();
