(function(){
   "use strict";

   angular.module("app.services").factory('Dialog', function( $mdDialog ) {

      function fullScreen(name, controller, locals, event) {
         return $mdDialog.show({
            clickOutsideToClose: true,
            templateUrl: '/views/dialogs/' + name + '/' + name + '.html',
            controller: controller,
            targetEvent : event,
            locals : locals,
            fullscreen : true
         });
      }

      function fromTemplate(name, controller, locals, event) {
         return $mdDialog.show({
            clickOutsideToClose: true,
            templateUrl: '/views/dialogs/' + name + '/' + name + '.html',
            controller: controller,
            targetEvent : event,
            locals : locals
         });
      }

      function confirm(title, content, event, successCallback, errorCallback) {
         var confirm = $mdDialog.confirm()
				.title( title )
				.textContent( content )
				.targetEvent( event )
				.ok( 'Ok' )
				.cancel( 'Cancel' );
         $mdDialog.show(confirm).then(successCallback, errorCallback);
      }

      return {
         fromTemplate : fromTemplate,
         fullScreen : fullScreen,
         confirm : confirm
      };
   });
})();
