(function(){
   "use strict";

   angular.module("app.controllers").controller('LandingController', function( $scope, Product ) {

      Product.find(function(products) {
         $scope.products = products.slice(0, 6);
      }, function() {

      });

      $scope.scrollToLatestProducts = function() {
         console.log($("#scrollTarget").offset().top - $("#wrapper").offset().top + $("#wrapper").scrollTop());
         $("#wrapper").animate({
            scrollTop: $("#scrollTarget").offset().top - $("#wrapper").offset().top + $("#wrapper").scrollTop()
         }, 500);
      };

   }).config(function($stateProvider) {
      $stateProvider.state('landing', {
         url: '/',
         views: {
            header: {
               templateUrl: '/views/app/public/header/header.html',
               controller: 'HeaderController'
            },
            sidenav: {
               templateUrl: '/views/app/public/sidenav/sidenav.html',
               controller: 'SidenavController'
            },
            main: {
               templateUrl: '/views/app/public/landing/landing.html',
               controller: 'LandingController'
            },
            footer: {
               templateUrl: '/views/app/public/footer/footer.html',
               controller: 'FooterController'
            }
         }
      });
   });
})();
