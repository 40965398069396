(function(){
   "use strict";

   angular.module("app.controllers").controller('ProductsAdminController', function( $scope, Product, Category ) {

      $scope.search = {};
      $scope.categories = Category.getCategories();

      $scope.$on("UPDATE.CATEGORIES", function(event, categories) {
         $scope.categories = Category.getCategories();
      });

      Product.find(function(products) {
         $scope.products = products;
      }, function() {

      });

      $scope.createNew = function(event) {
         Product.openForm({}, event).then(function(product) {
            $scope.products.unshift(product);
         }, function() {

         });
      };

      $scope.edit = function(product, event) {
         Product.openForm(product, event).then(function(updatedProduct) {
         }, function() {
         });
      };

      $scope.remove = function(product, event) {
         Product.remove(product, event, function() {
            $scope.products.splice($scope.products.indexOf(product), 1);
         }, function() {

         });
      };

   }).config(function($stateProvider) {
      $stateProvider.state('products-admin', {
         url: '/admin/products',
         views: {
            header: {
               templateUrl: '/views/app/public/header/header.html',
               controller: 'HeaderController'
            },
            sidenav: {
               templateUrl: '/views/app/public/sidenav/sidenav.html',
               controller: 'SidenavController'
            },
            main: {
               templateUrl: '/views/app/admin/products/products.html',
               controller: 'ProductsAdminController'
            }
         }
      });
   });

})();
