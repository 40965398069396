(function(){
   "use strict";

   angular.module("app.services").service("User", function( Dialog, Toast, UserRestService ) {

      function find(successCallback, errorCallback) {
         UserRestService.find(function(users) {
            successCallback(users);
         }, function() {
            errorCallback();
         });
      }

      function openForm(user, event) {
         return Dialog.fromTemplate("user-dialog", "UserDialogController", {
            user : user
         }, event);
      }

      function save(user, successCallback, errorCallback) {
         if (user.id) {
            UserRestService.update({userId : user.id}, user, function(user) {
               successCallback(user);
            }, function() {
               errorCallback();
            });
         } else {
            UserRestService.create(user, function(user) {
               successCallback(user);
            }, function() {
               errorCallback();
            });
         }
      }

      function remove(user, event, successCallback, errorCallback) {
         Dialog.confirm("Delete user", "Are you sure you want to delete this user?", event, function() {
            UserRestService.remove({userId : user.id}, {}, function() {
               Toast.show("Successfully deleted user.");
               successCallback();
            }, function() {
               Toast.show("Failed to delete user. Please try again.");
               errorCallback();
            });
         }, function() {
            errorCallback();
         });
      }

      return {
         find : find,
         openForm : openForm,
         save : save,
         remove : remove
      };

   });
})();
