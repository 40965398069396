(function(){
   "use strict";

   angular.module("app.directives").directive('sidenavBox', function() {
		return {
			scope : {},
			replace : true,
			templateUrl : "views/directives/sidebar/sidebar.html",
			controller:  function( $scope, Session ) {

            console.log("INIT SIDEBAR");
            $scope.categories = mapCategories(Session.getCategories());
            console.info($scope.categories);

            $scope.$on("UPDATE.CATEGORIES", function(event, categories) {
               $scope.categories = mapCategories(categories);
               console.info($scope.categories);
            });

            function mapCategories(categories) {
               var mappedCategories = [];
               angular.forEach(categories, function(category, index) {
                  if (!category.parent_category) {
                     mappedCategories.push(mapCategoryChildren(category, categories));
                  }
               });
               return mappedCategories;
            }

            function mapCategoryChildren(category, allCategories) {
               angular.forEach(allCategories, function(subCategory) {
                  if (subCategory.parent_category === category.id) {
                     if (category.categories) {
                        category.categories.push(mapCategoryChildren(subCategory, allCategories));
                     } else {
                        category.categories = [mapCategoryChildren(subCategory, allCategories)];
                     }
                  }
               });

               return category;
            }

            $scope.toggleSubCategories = function(category) {
               category.opened = !category.opened;
            };

         }
      }
   });
})();
