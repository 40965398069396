(function(){
   "use strict";

   angular.module("app.services").factory('Toast', function( $mdToast ) {

      var delay = 4000,
      position = 'bottom left',
      action = 'OK';

      function show(content) {
         return $mdToast.show(
            $mdToast.simple()
            .content(content)
            .position(position)
            .action(action)
            .hideDelay(delay)
         );
      }

      return {
         show: show
      };
   });
})();
