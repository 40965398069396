(function(){
   "use strict";

   angular.module("app.services").service("Product", function( Dialog, Toast, Category, ProductRestService ) {

      function find(successCallback, errorCallback) {
         ProductRestService.find(function(products) {
            angular.forEach(products, function(product) {
               if (product.category_id) {
                     product.category = Category.getById(product.category_id);
               }
            });
            successCallback(products);
         }, function() {
            errorCallback();
         });
      }

      function findByCategory(categoryId, successCallback, errorCallback) {
         ProductRestService.findByCategory({ categoryId : categoryId }, function(products) {
            angular.forEach(products, function(product) {
               if (product.category_id) {
                     product.category = Category.getById(product.category_id);
               }
            });
            successCallback(products);
         }, function() {
            errorCallback();
         });
      }

      function findByParentCategory(categoryId, successCallback, errorCallback) {
         ProductRestService.findByParentCategory({ categoryId : categoryId }, function(products) {
            angular.forEach(products, function(product) {
               if (product.category_id) {
                     product.category = Category.getById(product.category_id);
               }
            });
            successCallback(products);
         }, function() {
            errorCallback();
         });
      }

      function openForm(product, event) {
         return Dialog.fromTemplate("product-dialog", "ProductDialogController", {
            product : product
         }, event);
      }

      function openDetailedDialog(product, event) {
         return Dialog.fullScreen("product-detailed-dialog", "ProductDetailedDialogController", {
            product : product
         }, event);
      }

      function save(product, successCallback, errorCallback) {
         if (product.id) {
            ProductRestService.update({productId : product.id}, product, function(product) {
               product.category = Category.getById(product.category_id);
               successCallback(product);
            }, function() {
               errorCallback();
            });
         } else {
            ProductRestService.create(product, function(product) {
               product.category = Category.getById(product.category_id);
               successCallback(product);
            }, function() {
               errorCallback();
            });
         }
      }

      function remove(product, event, successCallback, errorCallback) {
         Dialog.confirm("Delete product", "Are you sure you want to delete this product?", event, function() {
            ProductRestService.remove({productId : product.id}, {}, function() {
               Toast.show("Successfully deleted product.");
               successCallback();
            }, function() {
               Toast.show("Failed to delete product. Please try again.");
               errorCallback();
            });
         }, function() {
            errorCallback();
         });
      }

      return {
         find : find,
         findByCategory : findByCategory,
         findByParentCategory : findByParentCategory,
         openForm : openForm,
         openDetailedDialog : openDetailedDialog,
         save : save,
         remove : remove
      };
   });
})();
