(function(){
   "use strict";

   // TODO: Resolve somewhere to config
   angular.module("app.controllers").controller('CategoryDialogController', function( $scope, $mdDialog, Category, category ) {

      $scope.category = category || {};
      $scope.categories = Category.getCategories();

      $scope.close = function() {
         $mdDialog.cancel();
      };

      $scope.save = function() {
         if ($scope.CategoryForm.$valid) {
            Category.save($scope.category, function(category) {
               $mdDialog.hide(category);
            }, function() {
               $mdDialog.hide($scope.category);
            });
         } else {
            $scope.showErrors = true;
         }
      };

   });
})();
