(function(){
   "use strict";

   angular.module("app.directives").directive('ndProduct', function() {
		return {
			scope : {
            product : "="
         },
			replace : true,
			templateUrl : "views/directives/product-item/product-item.html",
			controller:  function( $scope, $sce, Product ) {

            $scope.openDetailedDialog = function(event) {
               Product.openDetailedDialog($scope.product, event);
            };

            $scope.getDescriptionHtml = function() {
               return $sce.trustAsHtml($scope.product.description);
            };

         }
      }
   });
})();
