(function(){
   "use strict";

   angular.module("app.controllers").controller('CategoriesAdminController', function( $scope, Session, Category ) {

      $scope.categories = [];
      Category.updateCategories();

      $scope.$on("UPDATE.CATEGORIES", function(event, categories) {
         $scope.categories = Category.getCategories();
      });

      $scope.createNew = function(event) {
         Category.openForm({}, event).then(function(category) {
            $scope.categories.unshift(category);
         }, function() {

         });
      };

      $scope.edit = function(category, event) {
         Category.openForm(category, event).then(function(updatedCategory) {
            if (updatedCategory) {
               console.log("UPDATED");
            } else {
               console.log("DELETED");
            }
         }, function() {
         });
      };

      $scope.remove = function(category, event) {
         Category.remove(category, event, function() {
            $scope.categories.splice($scope.categories.indexOf(category), 1);
         }, function() {

         });
      };

      $scope.getParentCategory = function(parentCategoryId) {
         for (var x in $scope.categories) {
            if ($scope.categories[x].id === parentCategoryId) {
               return $scope.categories[x].name;
            }
         }
      };

   }).config(function($stateProvider) {
      $stateProvider.state('categories-admin', {
         url: '/admin/categories',
         views: {
            header: {
               templateUrl: '/views/app/public/header/header.html',
               controller: 'HeaderController'
            },
            sidenav: {
               templateUrl: '/views/app/public/sidenav/sidenav.html',
               controller: 'SidenavController'
            },
            main: {
               templateUrl: '/views/app/admin/categories/categories.html',
               controller: 'CategoriesAdminController'
            }
         }
      });
   });

})();
