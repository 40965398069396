(function(){
   "use strict";

   angular.module("app.controllers").controller('UsersAdminController', function( $scope, Session, User ) {

      User.find(function(users) {
         $scope.users = users;
      }, function() {

      });

      $scope.createNew = function(event) {
         User.openForm({}, event).then(function(user) {
            $scope.users.unshift(user);
         }, function() {

         });
      };

      $scope.edit = function(user, event) {
         User.openForm(user, event).then(function(updatedUser) {
         }, function() {
         });
      };

      $scope.remove = function(user, event) {
         User.remove(user, event, function() {
            $scope.users.splice($scope.users.indexOf(user), 1);
         }, function() {

         });
      };


   }).config(function($stateProvider) {
      $stateProvider.state('users-admin', {
         url: '/admin/users',
         views: {
            header: {
               templateUrl: '/views/app/public/header/header.html',
               controller: 'HeaderController'
            },
            sidenav: {
               templateUrl: '/views/app/public/sidenav/sidenav.html',
               controller: 'SidenavController'
            },
            main: {
               templateUrl: '/views/app/admin/users/users.html',
               controller: 'UsersAdminController'
            }
         }
      });
   });

})();
