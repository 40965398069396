(function(){
   "use strict";

   angular.module("app.controllers").controller('ProductDialogController', function( $scope, $auth, $timeout, $mdDialog, Category, Product, product, FileUploader ) {

      $scope.uploader = new FileUploader();
      $scope.uploader.queueLimit = 1;
      $scope.product = product || {};
      $scope.categories = Category.getCategories();

      var UploadProperties = {
         url : "/api/product" + ($scope.product.id ? ("/" + $scope.product.id) : ""),
         alias : "picture",
         method : "POST",
         removeAfterUpload : true,
      };

      $scope.uploader.onAfterAddingFile = function(item) {
         $scope.image = item;

         var reader = new FileReader();

         reader.onload = function (e) {
            $timeout(function() {
               $scope.imageSource = e.target.result;
            });
         };

         reader.readAsDataURL(item._file);

         console.log(item);
      };

      $scope.updateCategory = function() {
         $scope.product.category = Category.getById($scope.product.category_id);
      };

      $scope.openFileSelect = function() {
         document.getElementById("fileSelect").click();
      };

      $scope.close = function() {
         $mdDialog.cancel();
      };

      $scope.save = function() {
         if ($scope.ProductForm.$valid) {
            if ($scope.image) {
               saveWithImage();
            } else {
               saveWithoutImage();
            }
         } else {
            $scope.showErrors = true;
         }
      };

      function saveWithoutImage() {
         Product.save($scope.product, function(product) {
            $mdDialog.hide(product);
         }, function() {
            $mdDialog.hide();
         });
      }

      function saveWithImage() {
         var image = $scope.image;
         image.url = UploadProperties.url;
         image.alias = UploadProperties.alias;
         image.method = UploadProperties.method;
         image.removeAfterUpload = UploadProperties.removeAfterUpload;
         image.formData = convertProductToArray();
         image.headers = { "Authorization" : "Bearer " + $auth.getToken() };

         image.onSuccess = function(product) {
            $mdDialog.hide(product);
         };

         image.upload();
      }

      function convertProductToArray() {
         var formData = new Array();
         for (var key in $scope.product) {
            var keyValuePair = {};
            keyValuePair[key] = $scope.product[key];
            formData.push(keyValuePair);
         }
         return formData;
      }

   });
})();
