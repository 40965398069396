(function(){
   "use strict";

   angular.module("app.services").config(function($authProvider) {
      $authProvider.loginUrl = "/api/authenticate";
   }).service('Session', function( $rootScope , $auth, Toast, Dialog) {

      function login(credentials, successCallback, errorCallback) {
         $auth.login(credentials).then(function(data) {
            Toast.show("Successfully logged in!");
            successCallback();
         }).catch(function(response) {
            errorCallback();
         });
      }

      function isAuthenticated() {
         return $auth.isAuthenticated();
      }

      function logout(event) {
         Dialog.confirm("Log out", "Are you sure you want to log out?", event, function() {
            $auth.logout();
         }, function() {
         });
      }

      return {
         login : login,
         logout : logout,
         isAuthenticated : isAuthenticated
      };
   });
})();
