(function(){
   "use strict";

   angular.module("app.services").service("Category", function( $rootScope, Dialog, Toast, CategoryRestService ) {

      updateCategories();

      function updateCategories() {
         CategoryRestService.find(function(categories) {
            $rootScope.categories = angular.copy(categories);
            $rootScope.builtCategories = mapCategories(angular.copy(categories));
            $rootScope.$broadcast("UPDATE.CATEGORIES", $rootScope.builtCategories);
         }, function() {
            $rootScope.$broadcast("UPDATE.CATEGORIES", null);
         });
      }

      function getCategories() {
         return $rootScope.categories;
      }

      function getBuiltCategories() {
         return $rootScope.builtCategories;
      }

      function openForm(category, event) {
         return Dialog.fromTemplate("category-dialog", "CategoryDialogController", {
            category : category
         }, event);
      }

      function save(category, successCallback, errorCallback) {
         if (category.id) {
            CategoryRestService.update({categoryId : category.id}, category, function(category) {
               successCallback(category);
            }, function() {
               errorCallback();
            });
         } else {
            CategoryRestService.create(category, function(category) {
               successCallback(category);
            }, function() {
               errorCallback();
            });
         }
      }

      function remove(category, event, successCallback, errorCallback) {
         Dialog.confirm("Delete category", "Are you sure you want to delete this category?", event, function() {
            CategoryRestService.remove({categoryId : category.id}, {}, function() {
               Toast.show("Successfully removed category.");
               successCallback();
            }, function() {
               Toast.show("Failed to remove category. Please try again.");
               errorCallback();
            });
         }, function() {
            errorCallback();
         });
      }

      function getById(id) {
         var searchedCategory = null;
         angular.forEach(angular.copy($rootScope.categories), function(category) {
            if (category.id === parseInt(id)) {
               searchedCategory = category;
            }
         });
         return searchedCategory;
      }

      function mapCategories(categories) {
         var mappedCategories = [];
         increaseParentCount(categories);
         angular.forEach(categories, function(category, index) {
            if (!category.parent_category) {
               mappedCategories.push(mapCategoryChildren(category, categories));
            }
         });
         return mappedCategories;
      }

      function increaseParentCount(categories) {
         angular.forEach(categories, function(category) {
            angular.forEach(categories, function(cat) {
               if (category.id === cat.parent_category) {
                  category.count += cat.count;
               }
            });
         });
      }

      function getAllCount() {
         var count = 0;
         angular.forEach($rootScope.categories, function(category) {
            count += category.count;
         });
         return count;
      }

      function mapCategoryChildren(category, allCategories) {
         angular.forEach(allCategories, function(subCategory) {
            if (subCategory.parent_category === category.id) {
               if (category.categories) {
                  category.categories.push(mapCategoryChildren(subCategory, allCategories));
               } else {
                  category.categories = [mapCategoryChildren(subCategory, allCategories)];
               }
            }
         });

         return category;
      }

      function isParent(categoryId) {
         var isParent = false;
         angular.forEach($rootScope.categories, function(category) {
            if (category.parent_category === parseInt(categoryId)) {
               isParent = true;
            }
         });
         return isParent;
      }

      return {
         getCategories : getCategories,
         getBuiltCategories : getBuiltCategories,
         getAllCount : getAllCount,
         updateCategories : updateCategories,
         isParent : isParent,
         openForm : openForm,
         save : save,
         remove : remove,
         getById : getById
      };
   });
})();
