(function(){
   "use strict";

   angular.module("app.controllers").controller('LoginDialogController', function( $scope, $mdDialog, Session ) {

      $scope.credentials = {};
      $scope.showErrors = false;

      $scope.close = function() {
         $mdDialog.cancel();
      };

      $scope.login = function() {
         if ($scope.LoginForm.$valid) {
            Session.login($scope.credentials, function() {
               $mdDialog.hide();
            }, function() {
               
            });
         } else {
            $scope.showErrors = true;
         }
      };

   });
})();
