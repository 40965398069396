(function(){
   "use strict";

   angular.module("app.services").service('UserRestService', function( $resource ) {
      return $resource("/api/user/:userId", { userId : "@userId" }, {

         find : { method : "GET", isArray : true },
         findById : { method : "GET" },
         create : { method : "POST" },
         update : { method : "POST" },
         remove : { method : "DELETE", headers: [{'Content-Type': 'application/json'}] }

      });
   });
})();
